import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { alpha, useTheme } from "@mui/material/styles";
import { IconButton, darken, Tooltip, } from "@mui/material";
function RoundActionIconButtonWrapper({ tooltipMsg, children, tooltipPlacement, }) {
    return tooltipMsg ? (_jsx(Tooltip, Object.assign({ title: tooltipMsg, arrow: true, enterDelay: 1000, placement: tooltipPlacement }, { children: children }))) : (_jsx(_Fragment, { children: children }));
}
export function RoundActionIconButton({ onClick, tooltipMsg, size, active, children, paletteCategory, colorOnHover, disabled, baseColor, baseBackgroundColor, tooltipPlacement, }) {
    const theme = useTheme();
    const highlightColor = theme.palette[paletteCategory ? paletteCategory : "primary"]["main"];
    const highlightBackgroundColor = highlightColor
        ? alpha(highlightColor, 0.3)
        : undefined;
    const baseColorDisabled = baseColor && darken(baseColor, 0.4);
    const color = active ? highlightColor : baseColor;
    const backgroundColor = active
        ? highlightBackgroundColor
        : baseBackgroundColor;
    return (_jsx(RoundActionIconButtonWrapper, Object.assign({ tooltipMsg: tooltipMsg, tooltipPlacement: tooltipPlacement }, { children: _jsxs(IconButton, Object.assign({ disabled: disabled, onClick: onClick, size: size, sx: {
                color: color,
                backgroundColor: backgroundColor,
                transition: "background 0.5s, color 0.5s",
                "&:hover": colorOnHover && !disabled
                    ? {
                        color: highlightColor,
                        // backgroundColor: highlightBackgroundColor,
                    }
                    : undefined,
                "&.Mui-disabled": {
                    color: baseColorDisabled,
                },
            } }, { children: [" ", children] })) })));
}
