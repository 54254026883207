import { FileType } from "../..";
import urlJoin from "url-join";
import { supportedFiles } from "../..";
import { getFilesFromSystemNode } from "../../3dViewer/stateTree";
function joinIfNotAbsolute(basePath, filePath) {
    if (filePath.charAt(0) === "/")
        return filePath;
    return urlJoin(basePath, filePath);
}
function updateDCPathInSystTree(system, basePath) {
    var _a;
    if (system.children) {
        system.children.forEach((child) => {
            updateDCPathInSystTree(child, basePath);
        });
    }
    else {
        system.files = (_a = system.files) === null || _a === void 0 ? void 0 : _a.map((f) => joinIfNotAbsolute(basePath, f));
    }
}
export function getFilesFullPath(configFile, basePath) {
    // Modify nap file so that it contains full paths
    for (let elementType in configFile) {
        const elData = configFile[elementType];
        if (Array.isArray(elData)) {
            elData.forEach((systOrOther) => {
                if (systOrOther.path) {
                    // it's a table, plot or MSA
                    systOrOther.path = joinIfNotAbsolute(basePath, systOrOther.path);
                    if ("mapping" in systOrOther) {
                        systOrOther.mapping = joinIfNotAbsolute(basePath, systOrOther.mapping);
                    }
                }
                else {
                    // it's a system
                    updateDCPathInSystTree(systOrOther, basePath);
                }
            });
        }
        else {
            if (elData.files) {
                elData.files = elData.files.map((fileName) => joinIfNotAbsolute(basePath, fileName));
            }
        }
    }
}
export function getFileType(mol, singleResidue, predefinedType) {
    if (mol && mol.numFrames > 1) {
        return predefinedType ? predefinedType : FileType.trajectory;
    }
    return singleResidue ? FileType.sdf : FileType.coordinates_single;
}
const suppFileToFiletype = {
    coordinates_static: FileType.coordinates_single,
    coordinates_trajectory: FileType.trajectory,
    topology: FileType.trajectory,
    volume: FileType.volume,
};
export const extensionToFileType = {};
Object.entries(supportedFiles)
    .filter((e) => e[0] !== "loading_batch")
    .forEach((e) => {
    const [suppfile, extli] = e;
    extli.forEach((ext) => {
        if (suppfile in FileType) {
            const _suppfile = suppfile;
            const ftype = suppFileToFiletype[_suppfile];
            extensionToFileType[ext] = ftype;
        }
    });
});
export function toAbsPath(base, mypath) {
    return mypath.charAt(0) === "/" ? mypath : urlJoin(base, mypath);
}
export function findConfigFile(acceptedFiles) {
    let _acceptedFiles = acceptedFiles instanceof FileList
        ? Array.from(acceptedFiles)
        : acceptedFiles;
    const configArray = [];
    _acceptedFiles.forEach((myFile) => {
        const ext = myFile.name.split(".").pop();
        if (ext && supportedFiles.instructions.includes(ext)) {
            configArray.push(myFile);
        }
    });
    return configArray;
}
export function getFilesInConfig(loadingInstructions) {
    // Find files inputted but not found in the config file
    const filesInInstructions = [];
    for (let elementType in loadingInstructions) {
        if (elementType === "systems") {
            if (!loadingInstructions.systems)
                continue;
            loadingInstructions.systems.forEach((system) => {
                const files = getFilesFromSystemNode(system);
                filesInInstructions.push(...files);
            });
        }
        else {
            if (!loadingInstructions[elementType])
                continue;
            loadingInstructions[elementType].forEach((el) => {
                filesInInstructions.push(el.path);
                if ("mapping" in el) {
                    filesInInstructions.push(el.mapping);
                }
            });
        }
    }
    return filesInInstructions;
}
export function getFilesNotInConfig(filesInInstructions, acceptedFilesObject) {
    const loadedFiles = Object.keys(acceptedFilesObject);
    return loadedFiles.filter((file) => !filesInInstructions.includes(file) &&
        !file.endsWith(".pmv") &&
        !file.endsWith(".nap"));
}
function getRelativePath(basePath, filePath) {
    // Generates a relative path from basePath to filePath
    // Split both paths into parts
    const baseParts = basePath.split("/").filter(Boolean);
    const fileParts = filePath.split("/").filter(Boolean);
    // Find the common prefix
    let i = 0;
    while (i < baseParts.length &&
        i < fileParts.length &&
        baseParts[i] === fileParts[i]) {
        i++;
    }
    // Steps to go up from basePath
    const backSteps = baseParts.slice(i).map(() => "..");
    // Remaining steps to the target file
    const forwardSteps = fileParts.slice(i);
    // Combine back steps and forward steps
    return [...backSteps, ...forwardSteps].join("/");
}
export function convertLoadedFilesPathToRelative(acceptedFilesObject, basePath) {
    for (const fileName in acceptedFilesObject) {
        const file = acceptedFilesObject[fileName];
        const prevPath = file.name;
        const cleanPath = getRelativePath(basePath, prevPath);
        file["name"] = cleanPath;
        acceptedFilesObject[cleanPath] = file;
        delete acceptedFilesObject[prevPath];
    }
}
