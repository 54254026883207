// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { binFormats } from "../utils/FileUpload/supportedFiles";
import { checkForExtensionsInTree } from "../3dViewer/stateTree";
export function getDateNow() {
    const date = new Date(Date.now());
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const hrs = date.getHours();
    const min = date.getMinutes();
    const dateformat = `${y}-${m}-${d}_${hrs}-${min}`;
    return dateformat;
}
export function warnIfExtensionsNotFound(vss, extensions, callback) {
    // Check if there exist any system with the given extension
    const systems = vss.getState().loaded_structures;
    const foundExt = checkForExtensionsInTree(systems, extensions);
    if (!foundExt) {
        callback(extensions);
    }
    else {
        callback([]);
    }
}
function convertToArrayBufferView(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
            const arraybuff = reader.result;
            const arr = new Uint8Array(arraybuff);
            resolve(arr);
        };
    });
}
function fileToContentString(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => {
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
    });
}
export function writeFileInVFS(pyodide, file) {
    return __awaiter(this, void 0, void 0, function* () {
        const ext = file.name.split(".").pop();
        const _binFormats = [...binFormats, "ckpt"];
        const isBinary = ext && _binFormats.includes(ext);
        const tmpDir = new Date(Date.now())
            .toISOString()
            .slice(0, 19)
            .replace(/\D/g, "");
        const tmpPath = `/tmp/${tmpDir}`;
        pyodide.FS.mkdir(tmpPath);
        const filePath = `${tmpPath}/${file.name}`;
        if (isBinary) {
            const fileArr = yield convertToArrayBufferView(file);
            pyodide.FS.writeFile(filePath, fileArr, "binary");
        }
        else {
            const fileStr = yield fileToContentString(file);
            pyodide.FS.writeFile(filePath, fileStr, "utf8");
        }
        return filePath;
    });
}
export function flushFolderInVFS(pyodide, path) {
    return __awaiter(this, void 0, void 0, function* () {
        pyodide.RunPythonAsync({
            context: { path },
            script: `
      from js import path
      import os, shutil

      folder = path
      
      if os.path.isdir(folder):
        for filename in os.listdir(folder):
          file_path = os.path.join(folder, filename)
          try:
            if os.path.isfile(file_path) or os.path.islink(file_path):
              os.unlink(file_path)
            elif os.path.isdir(file_path):
              shutil.rmtree(file_path)
          except Exception as e:
            print('Failed to delete %s. Reason: %s' % (file_path, e))
      `,
        });
    });
}
