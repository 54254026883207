import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Checkbox, Grid, Fade } from "@mui/material";
export function SystemCheckbox({ boxKey, onCheckSystem, checkedSystems, areSystemsCheckboxActive, disabledCheckboxSystems, }) {
    const isDisabled = disabledCheckboxSystems && disabledCheckboxSystems.includes(boxKey);
    return (_jsx(Fade, Object.assign({ in: areSystemsCheckboxActive, unmountOnExit: true }, { children: _jsx(Checkbox, { disabled: isDisabled, checked: !isDisabled && checkedSystems && checkedSystems.includes(boxKey), size: "small", sx: {
                pr: 0,
                "& .MuiSvgIcon-root": {
                    width: "1em",
                    height: "1em",
                    color: isDisabled ? undefined : "secondary.main",
                },
            }, onChange: (e) => onCheckSystem ? onCheckSystem(e.target.checked, boxKey) : null }) })));
}
export function ToggleAllGroups({ areSystemsCheckboxActive, onCheckToggleAll, isToggleAllChecked, }) {
    const handleChange = (e) => {
        const isChecked = e.target.checked;
        onCheckToggleAll(isChecked);
    };
    return (_jsx(Fade, Object.assign({ in: areSystemsCheckboxActive, unmountOnExit: true }, { children: _jsx(Grid, Object.assign({ container: true, alignItems: "center" }, { children: _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Checkbox, { sx: { "& .MuiSvgIcon-root": { color: "secondary.main" } }, checked: isToggleAllChecked, size: "small", onChange: handleChange }) })) })) })));
}
