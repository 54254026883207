function updateDCPathInSystTree(systems, folderId) {
    systems.forEach((system) => {
        var _a;
        if (system.children) {
            updateDCPathInSystTree(system.children, folderId);
        }
        else {
            system.files = (_a = system.files) === null || _a === void 0 ? void 0 : _a.map((f) => `${folderId}?files=${f}`);
        }
    });
}
export function addPathToNap(napFile, folderId) {
    const { systems, tables, MSAs, plots } = napFile;
    if (systems) {
        updateDCPathInSystTree(systems, folderId);
    }
    if (tables) {
        tables.forEach((table) => {
            table.path = `${folderId}?files=${table.path}`;
        });
    }
    if (MSAs) {
        MSAs.forEach((msa) => {
            msa.path = `${folderId}?files=${msa.path}`;
            if ("mapping" in msa) {
                msa.mapping = `${folderId}?files=${msa.mapping}`;
            }
        });
    }
    if (plots) {
        if (Array.isArray(plots)) {
            plots.forEach((plot) => {
                plot.path = `${folderId}?files=${plot.path}`;
            });
        }
        else {
            //@ts-ignore
            plots.files = plots.files.map(
            // remove once app manifests with plots in the output are updated
            (plotFile) => `${folderId}?files=${plotFile}`);
        }
    }
}
function recursiveGetSystemFiles(systems) {
    let files = [];
    for (const system of systems) {
        if (system.files) {
            files.push(...system.files);
        }
        if (system.children) {
            const childFiles = recursiveGetSystemFiles(system.children);
            files.push(...childFiles);
        }
    }
    return files;
}
export function getFilesToDownload(napFile) {
    const { systems, tables, MSAs, plots } = napFile;
    let filePaths = [];
    if (systems) {
        const systemFiles = recursiveGetSystemFiles(systems);
        filePaths = filePaths.concat(systemFiles);
    }
    if (tables) {
        tables.forEach((table) => {
            filePaths.push(table.path);
        });
    }
    if (MSAs) {
        MSAs.forEach((msa) => {
            filePaths.push(msa.path);
            if ("mapping" in msa && msa.mapping) {
                filePaths.push(msa.mapping);
            }
        });
    }
    if (plots) {
        if (Array.isArray(plots)) {
            plots.forEach((plot) => {
                filePaths.push(plot.path);
            });
        }
        else {
            //@ts-ignore
            filePaths = filePaths.concat(plots.files); // remove once app manifests with plots in the output are updated
        }
    }
    return filePaths;
}
export function dsInfoFromId(file_id) {
    let ds_id = file_id;
    let files = [];
    if (isNaN(Number(file_id))) {
        const regexPattern = /dc:\/\/(\d+)(?:\?files=([^&]+))?/;
        const match = regexPattern.exec(file_id);
        if (!match)
            return { ds_id: undefined, files: undefined };
        const [_, number, filesStr] = match;
        files = filesStr ? filesStr.split(",") : [];
        ds_id = number;
    }
    return { ds_id: Number(ds_id), files: files };
}
export function getFilesType(files) {
    // checks if the file list to be downloaded from the data center constitute a directory
    let isDir = false;
    let ext;
    if (!files) {
        isDir = true;
    }
    else {
        if (files.length !== 1) {
            isDir = true;
        }
        else {
            const fileName = files[0].replace(/\/+$/, "").split("/").pop();
            const fileNameSplit = fileName === null || fileName === void 0 ? void 0 : fileName.split(".");
            if (fileNameSplit && fileNameSplit.length <= 1) {
                isDir = true;
            }
            ext = fileNameSplit
                ? fileNameSplit.length > 1
                    ? fileNameSplit === null || fileNameSplit === void 0 ? void 0 : fileNameSplit.pop()
                    : "zip"
                : "zip";
        }
    }
    return { isDir: isDir, zipExt: ext === "zip" };
}
