// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import produce from "immer";
import { sanitizeFilename } from "../../3dViewer/DownloadSystem/downloadSystem";
import { FileType, supportedFiles, } from "../../utils";
import uuidv4 from "../../utils/UUID";
import { BlobReader } from "@zip.js/zip.js";
import { findSystemByKey } from "../../3dViewer/stateTree";
export function getDateNow() {
    const date = new Date(Date.now());
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const hrs = date.getHours();
    const min = date.getMinutes();
    const dateformat = `${y}-${m}-${d}_${hrs}-${min}`;
    return dateformat;
}
export function getZipFileNameBase(name, id_str) {
    return `${id_str}_${sanitizeFilename(name)}`;
}
const getSystemsDataToZipRecursive = (system, savedSystems = {}, zipWriter, pyodide) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, e_1, _b, _c, _d, e_2, _e, _f;
    if (system.children) {
        try {
            for (var _g = true, _h = __asyncValues(system.children), _j; _j = yield _h.next(), _a = _j.done, !_a;) {
                _c = _j.value;
                _g = false;
                try {
                    const child = _c;
                    yield getSystemsDataToZipRecursive(child, savedSystems, zipWriter, pyodide);
                }
                finally {
                    _g = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_g && !_a && (_b = _h.return)) yield _b.call(_h);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else {
        if (!system.moleculeID)
            return;
        const fileNameBase = getZipFileNameBase(system.name, system.moleculeID);
        // save mol files to VFS, get the file path
        const systResult = yield pyodide.RunPythonAsync({
            context: {},
            script: `
    _systs_export._save_system("${system.moleculeID}", "${fileNameBase}")
    `,
        });
        if (!systResult)
            return;
        try {
            for (var _k = true, systResult_1 = __asyncValues(systResult), systResult_1_1; systResult_1_1 = yield systResult_1.next(), _d = systResult_1_1.done, !_d;) {
                _f = systResult_1_1.value;
                _k = false;
                try {
                    const fileData = _f;
                    // add it to the zip
                    yield addVFSFileToZip(zipWriter, fileData.filePath, fileData.fileName, pyodide);
                    // remove mol files from VFS
                    yield pyodide.RunPythonAsync({
                        context: {},
                        script: `
      remove_if_possible("${fileData.filePath}")
      `,
                    });
                }
                finally {
                    _k = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_k && !_d && (_e = systResult_1.return)) yield _e.call(systResult_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        // store saved file names to dict
        savedSystems[system.moleculeID] = systResult.map((fileData) => fileData.fileName);
    }
});
export function getSystemsDataToZip(zipWriter, systemsTree, pyodide) {
    var _a, systemsTree_1, systemsTree_1_1;
    var _b, e_3, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const savedSystems = {};
        yield pyodide.RunPythonAsync({
            context: {},
            script: `
  _systs_export = ExportSessionSystems()
  `,
        });
        try {
            for (_a = true, systemsTree_1 = __asyncValues(systemsTree); systemsTree_1_1 = yield systemsTree_1.next(), _b = systemsTree_1_1.done, !_b;) {
                _d = systemsTree_1_1.value;
                _a = false;
                try {
                    const system = _d;
                    yield getSystemsDataToZipRecursive(system, savedSystems, zipWriter, pyodide);
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = systemsTree_1.return)) yield _c.call(systemsTree_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        yield pyodide.RunPythonAsync({
            context: {},
            script: `
  _systs_export.delete()
  `,
        });
        return savedSystems;
    });
}
function cleanTreeForDownloadRecursive(system) {
    if (system.children) {
        system.children.forEach((childSyst) => cleanTreeForDownloadRecursive(childSyst));
    }
    else {
        delete system["cellRef"];
        delete system["moleculeID"];
        delete system["stateTreePosition"];
        delete system["category"];
        delete system["archived"];
        if (system.type === FileType.volume) {
            const volParams = system.volumeRepresentation;
            delete volParams.minIsoValue;
            delete volParams.maxIsoValue;
        }
        else {
            if (system.representations) {
                system.representations.forEach((rep) => {
                    delete rep.refs;
                });
            }
        }
    }
}
export function cleanTreeForDownload(systems) {
    // Cleans the tree for download by removing unnecessary data.
    const downloadTree = produce(systems, (draft) => {
        draft.forEach((system) => {
            cleanTreeForDownloadRecursive(system);
        });
    });
    return downloadTree;
}
function updateTreeFilesRecursive(system, savedSystemsFiles) {
    if (system.children) {
        system.children.forEach((child) => {
            updateTreeFilesRecursive(child, savedSystemsFiles);
        });
    }
    else {
        let systFiles;
        const molId = system.moleculeID;
        if (molId) {
            const savedFiles = savedSystemsFiles[molId];
            if (savedFiles) {
                systFiles = savedFiles;
            }
        }
        if (system.files)
            system.original_files = [...system.files];
        system.files = systFiles;
    }
}
function filterSystems(system) {
    let keep = true;
    const newSystem = produce(system, (draftSystem) => {
        if (draftSystem.children) {
            const filteredChildren = draftSystem.children
                .map(filterSystems)
                .filter((child) => child !== null);
            if (filteredChildren.length === 0)
                keep = false;
            draftSystem.children = filteredChildren;
        }
        else {
            if (!draftSystem.files || draftSystem.files.length === 0)
                keep = false;
        }
    });
    if (!keep)
        return null;
    return newSystem;
}
function filterSystemsTree(systems) {
    return systems
        .map(filterSystems)
        .filter((system) => system !== null);
}
export function updateTreeFiles(systems, savedSystemsFiles) {
    // Updates the system files based on the files created in the zip. Systems with missing files are removed.
    const newSystems = produce(systems, (draft) => {
        draft.forEach((system) => {
            updateTreeFilesRecursive(system, savedSystemsFiles);
        });
    });
    let missingFiles = [];
    const filtSystems = filterSystemsTree(newSystems);
    return { missingFiles, filtSystems };
}
export const getMSAMappingConfig = (msaMapping, systemsTree) => {
    const mappingData = msaMapping.posToStruc;
    let mappingRefPath = "";
    const refMolId = msaMapping.refMolId;
    if (systemsTree) {
        const refSystem = findSystemByKey(systemsTree, "moleculeID", refMolId);
        const refSystFiles = refSystem === null || refSystem === void 0 ? void 0 : refSystem.files;
        if (refSystFiles) {
            const refSystFilesTop = refSystFiles.filter((fileName) => !supportedFiles.coordinates_trajectory.includes(fileName.split(".").pop() || ""));
            if (refSystFilesTop.length > 0) {
                mappingRefPath = refSystFilesTop[0];
            }
        }
    }
    if (mappingRefPath) {
        const mappingConf = JSON.stringify({
            file: mappingRefPath,
            mapping: mappingData,
        });
        const mappingConfFileNamePre = msaMapping.filePath.replace(".json", "");
        const mappingConfFileName = `${getZipFileNameBase(mappingConfFileNamePre, uuidv4())}.json`;
        return { mappingConf, mappingConfFileName };
    }
};
export const addVFSFileToZip = (zipWriter, vfsFilePath, fineNaleInZip, pyodide) => __awaiter(void 0, void 0, void 0, function* () {
    const content = yield pyodide.FS.readFile(vfsFilePath, "binary");
    const contentU8a = new Uint8Array(content);
    const blob = new Blob([contentU8a]);
    yield zipWriter.add(fineNaleInZip, new BlobReader(blob));
});
export const downloadZip = (zipBlob, zipFileName) => __awaiter(void 0, void 0, void 0, function* () {
    // const zipBlob = await zipWriter.close();
    const url = URL.createObjectURL(zipBlob);
    const anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.download = zipFileName;
    anchorElement.click();
    window.URL.revokeObjectURL(url);
});
