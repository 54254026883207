// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import produce from "immer";
import { reconstructCSVCellVal } from "./tables.store";
export const NAPMSAStore = create()((set, get) => ({
    msaArr: [],
    activeMSAIdx: 0,
    hoveredMsaPos: -1,
    mouseDownPosition: -1,
    lastHoveredMsaPosInSelection: -1,
    syncColorActive: false,
    set_msaArr: (x) => set({ msaArr: x }),
    add_msa: (x) => set((state) => ({
        msaArr: [...state.msaArr, x],
        activeMSAIdx: get().msaArr.length,
    })),
    add_msas: (x) => {
        set((state) => ({
            msaArr: [...state.msaArr, ...x],
            activeMSAIdx: get().msaArr.length,
        }));
    },
    del_msa: (idx) => set((state) => {
        const updated_msa_arr = produce(state.msaArr, (draft) => {
            draft.splice(idx, 1);
        });
        return { msaArr: updated_msa_arr, activeMSAIdx: 0 };
    }),
    setActiveMSAIdx: (x) => set({ activeMSAIdx: x }),
    setHoveredMsaPos: (x) => set({ hoveredMsaPos: x }),
    setMouseDownPosition: (x) => set({ mouseDownPosition: x }),
    setLastHoveredMsaPosInSelection: (x) => set({ lastHoveredMsaPosInSelection: x }),
    setMSAColoring: (x) => set(produce((state) => {
        const activeMSA = state.msaArr[state.activeMSAIdx];
        if (activeMSA) {
            activeMSA.coloring = x;
        }
    })),
    setSyncColorActive: (x) => set({ syncColorActive: x }),
}));
export function createCSVBlobfromMSA(msaData) {
    const chunkSize = 1000;
    const keys = Object.keys(msaData);
    const contentGenerator = function* () {
        let i = 0;
        while (i < keys.length) {
            const chunk = keys
                .slice(i, i + chunkSize)
                .map((key) => {
                return reconstructCSVCellVal(key) + "," + msaData[key].join(",");
            })
                .join("\n");
            yield `${chunk}\n`;
            i += chunkSize;
        }
    };
    //@ts-ignore
    return new Blob(contentGenerator(), { type: "text/csv" });
}
