var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { createNewSystemFromPDB } from "../../FileLoaders/createNewSystem";
export function PDBIDInput({ disabled, vss, molstar, pyodide, onSuccessCallback, }) {
    const [fieldContent, setFieldContent] = useState({ value: "", error: "" });
    const [wait, setWait] = useState(false);
    const disableConfirm = disabled || !!fieldContent.error || wait;
    const handleLoadPDB = () => __awaiter(this, void 0, void 0, function* () {
        const value = fieldContent.value;
        if (!(value.length === 4 && /^[a-zA-Z0-9]+$/.test(value))) {
            setFieldContent((prev) => ({
                value: prev.value,
                error: "The PDB ID must be 4 characters-long and alphanumeric.",
            }));
            return;
        }
        setWait(true);
        const loadedSystem = yield createNewSystemFromPDB(value, vss, molstar, pyodide);
        if (loadedSystem) {
            onSuccessCallback(!!loadedSystem);
        }
        setWait(false);
    });
    const handleInputChange = (value) => {
        let finalValue = value;
        if (value.length > 4) {
            finalValue = value.slice(0, 4);
        }
        let error = "";
        if (!(/^[a-zA-Z0-9]+$/.test(finalValue) || finalValue === "")) {
            error = "The PDB ID must be alphanumeric.";
        }
        setFieldContent({
            value: finalValue,
            error,
        });
    };
    return (_jsx(TextField, { fullWidth: true, disabled: disabled || wait, type: "text", error: !!fieldContent.error, sx: {
            my: 1,
            "& .MuiOutlinedInput-root": {
                backgroundColor: "#f6f4ee",
            },
        }, variant: "outlined", value: fieldContent.value, size: "small", onChange: (e) => handleInputChange(e.target.value), placeholder: "Enter a PDB ID", onKeyDown: (e) => {
            if (e.key === "Enter" && !disableConfirm) {
                handleLoadPDB();
            }
        }, InputProps: {
            endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(Tooltip, Object.assign({ title: "Load", arrow: true, placement: "top" }, { children: _jsx(Button, Object.assign({ variant: "contained", size: "small", onClick: handleLoadPDB, style: { minWidth: "2rem", width: "2.5rem" }, disabled: disabled || wait }, { children: _jsx(SendIcon, { sx: { fontSize: "1rem", color: "#f6f4ee" } }) })) })) }))),
            sx: {
                fontSize: "0.875rem",
                pr: "5px",
            },
        } }));
}
