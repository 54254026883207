var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, CircularProgress, ListItemButton, ListItemIcon, Tooltip, } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { NAPPlotStore } from "../..";
import { NAPTableStore } from "../../StateStores/tables.store";
import { exportZip } from "./exportZip";
import { useState } from "react";
export const ExportButton = ({ vss, pyodide }) => {
    const [loading, setLoading] = useState(false);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield exportZip(pyodide, vss);
        setLoading(false);
    });
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Export Viewer State", arrow: true, placement: "right" }, { children: loading ? (_jsx(Box, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(CircularProgress, { size: "20px", sx: { m: "5px", color: "secondary.light" } }) }))) : (_jsx(ListItemButton, Object.assign({ onClick: handleClick, disabled: loading ||
                (vss.getState().loaded_structures.length === 0 &&
                    NAPTableStore.getState().tables.length === 0 &&
                    NAPPlotStore.getState().plots.length === 0) }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(SaveIcon, {}) })) }) }), "export-current-nap-state")) })));
};
