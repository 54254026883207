import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { useState, useEffect } from "react";
import { useCustomEvent } from "..";
import { Alert, Stack } from "@mui/material";
import uuidv4 from "../utils/UUID";
function NotificationAlert({ idx, message, type, fileName, onClose, }) {
    if (!onClose)
        throw Error("onClose callback is undefined");
    return (_jsxs(Alert, Object.assign({ onClose: () => onClose(idx), severity: type, sx: {
            maxWidth: "75%",
            maxHeight: "200px",
            overflowY: "auto",
        } }, { children: [fileName ? (_jsxs(_Fragment, { children: [_jsx("b", { children: fileName }), " - "] })) : (""), message] })));
}
function ControlledNotificationListener() {
    const [notifications, setNotifications] = useState({});
    const removeNotification = (key) => {
        setNotifications((prev) => {
            const updNotifications = produce(prev, (draft) => {
                delete draft[key];
            });
            return updNotifications;
        });
    };
    const _setNotifications = ({ message, type, idx, fileName, }) => {
        setNotifications((prev) => {
            return Object.assign(Object.assign({}, prev), { [idx]: { message: message, type: type, fileName } });
        });
    };
    useCustomEvent("PM-ControlledNotification", _setNotifications);
    useEffect(() => {
        const _cb = (e) => {
            const { idx } = e.detail;
            removeNotification(idx);
        };
        window.addEventListener("PM-DeleteControlledNotification", _cb);
        return () => {
            window.removeEventListener("PM-DeleteControlledNotification", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(notifications)]);
    return (_jsx(_Fragment, { children: Object.entries(notifications).map(([idx, notif], i) => {
            return (_jsx(NotificationAlert, { idx: idx, fileName: notif.fileName, message: notif.message, type: notif.type, onClose: removeNotification }, `controlled-notif-${i}`));
        }) }));
}
function NotificationListener() {
    const [notifications, setNotifications] = useState([]);
    const removeNotification = (id) => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    };
    const _setNotifications = ({ message, type, fileName, }) => {
        const newNotification = {
            message,
            type,
            fileName,
            id: uuidv4(),
        };
        setNotifications((prev) => [...prev, newNotification]);
        setTimeout(() => {
            removeNotification(newNotification.id);
        }, 10000);
    };
    useCustomEvent("PM-Notification", _setNotifications);
    return (_jsx(_Fragment, { children: notifications.map((notif) => {
            return (_jsx(NotificationAlert, { idx: `${notif.id}`, fileName: notif.fileName, message: notif.message, type: notif.type, onClose: removeNotification }, `pmv-notif-${notif.id}`));
        }) }));
}
export function NotificationStack() {
    return (_jsxs(Stack, Object.assign({ sx: {
            position: "absolute",
            top: 5,
            left: 0,
            right: 0,
            alignItems: "center",
            zIndex: 2000,
        }, spacing: 2 }, { children: [_jsx(NotificationListener, {}), _jsx(ControlledNotificationListener, {})] })));
}
