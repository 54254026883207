// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export const supportedFilesPre = {
    coordinates_static: [
        { ext: "pdb" },
        { ext: "sdf" },
        { ext: "mae", isBinary: true },
        { ext: "mol2" },
        { ext: "xyz" },
        { ext: "ent" },
        { ext: "pdbqt" },
        { ext: "crd" },
        { ext: "cif" },
        { ext: "bcif", isBinary: true },
        { ext: "prepi" },
        { ext: "mmtf", isBinary: true },
        { ext: "mmtf.gz", isBinary: true },
        { ext: "gjf" },
        { ext: "coor", isBinary: true },
    ],
    coordinates_trajectory: [
        { ext: "xtc", isBinary: true },
        { ext: "xsc" },
        { ext: "trr", isBinary: true },
        { ext: "dcd", isBinary: true },
        { ext: "netcdf", isBinary: true },
    ],
    topology: [
        { ext: "psf" },
        { ext: "prmtop" },
        { ext: "prm" },
        { ext: "top" },
        { ext: "rtf" },
    ],
    volume: [{ ext: "cube" }],
    plots: [{ ext: "plot.json" }, { ext: "png", isBinary: true }, { ext: "svg" }],
    tables: [{ ext: "csv" }],
    MSAs: [{ ext: "msa.csv" }],
    loading_batch: [{ ext: "pdb" }],
    instructions: [{ ext: "nap" }, { ext: "pmv" }],
    compressed: [{ ext: "zip", isBinary: true }],
};
const supportedFiles = {};
const binFormats = [];
for (const fileCategory in supportedFilesPre) {
    supportedFiles[fileCategory] = supportedFilesPre[fileCategory].map((file) => file.ext);
    binFormats.push(...supportedFilesPre[fileCategory]
        .filter((file) => file.isBinary)
        .map((file) => file.ext));
}
const jsonFormats = ["pmv", "nap", "json"];
export { supportedFiles, binFormats, jsonFormats };
