export function cleanMissingFiles(loadingInstructions, acceptedFilesObject) {
    // Remove from loadingInstructions the elements whose files are not found
    let missingFiles = [];
    for (let elementType in loadingInstructions) {
        if (elementType === "systems") {
            const systems = loadingInstructions.systems;
            if (systems) {
                const { missingFiles: missingFilesSysts, systemsFilt } = cleanSystemsMissingFiles(systems, acceptedFilesObject);
                loadingInstructions.systems = systemsFilt;
                missingFiles = missingFiles.concat(missingFilesSysts);
            }
        }
        else {
            if (!loadingInstructions[elementType])
                continue;
            const newElement = loadingInstructions[elementType].filter((el) => {
                if (!(el.path in acceptedFilesObject)) {
                    missingFiles.push(el.path);
                    return false;
                }
                return true;
            });
            loadingInstructions[elementType] = newElement;
        }
    }
    return missingFiles;
}
function cleanSystemsMissingFiles(systems, acceptedFilesObject) {
    let missingFiles = [];
    const systemsFilt = systems.filter((system) => {
        const missingFilesSystem = cleanSystemsMissingFilesRecursive(system, acceptedFilesObject);
        missingFiles = missingFiles.concat(missingFilesSystem);
        // Filter for the top-most elements in system
        if ((system.files && system.files.length === 0) ||
            (system.children && system.children.length === 0)) {
            return false;
        }
        return true;
    });
    return { missingFiles, systemsFilt };
}
function cleanSystemsMissingFilesRecursive(system, acceptedFilesObject) {
    var _a;
    let missingFiles = [];
    if (system.children) {
        system.children = system.children.filter((s) => {
            const mf = cleanSystemsMissingFilesRecursive(s, acceptedFilesObject);
            missingFiles = missingFiles.concat(mf);
            if ((s.files && s.files.length === 0) ||
                (s.children && s.children.length === 0))
                return false;
            return true;
        });
    }
    else {
        const isRemote = (_a = system.files) === null || _a === void 0 ? void 0 : _a.every((f) => f.startsWith("dc://"));
        if (system.files && !isRemote) {
            system.files = system.files.filter((f) => {
                if (!(f in acceptedFilesObject)) {
                    missingFiles.push(f);
                    return false;
                }
                return true;
            });
        }
    }
    return missingFiles;
}
