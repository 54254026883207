var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { dispatchConfirmationDialogEvent, } from "../../..";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { ViewerButtons } from "../../../components/Buttons/ActionIconButton/ViewerButtons";
export const GroupButton = ({ onClick, tooltipMsg, placement, disabled, active, }) => {
    return (_jsx(ViewerButtons, Object.assign({ onClick: onClick, tooltipMsg: tooltipMsg, size: "small", paletteCategory: "secondary", tooltipPlacement: placement, disabled: disabled, active: active }, { children: _jsx(FolderCopyIcon, { className: "detailsIcon", fontSize: "small" }) })));
};
export const VisibilityButton = ({ onClick, visible, tooltipMsg, placement, disabled, active, }) => {
    return (_jsx(ViewerButtons, Object.assign({ onClick: onClick, tooltipMsg: tooltipMsg, size: "small", paletteCategory: "secondary", tooltipPlacement: placement, disabled: disabled, active: active }, { children: visible ? (_jsx(VisibilityIcon, { className: "visibIcon", fontSize: "small" })) : (_jsx(VisibilityOffIcon, { className: "visibIcon", fontSize: "small" })) })));
};
export const SelectionModeButton = ({ onClick, tooltipMsg, placement, disabled, active, }) => {
    const _tooltipMsg = active
        ? "Disable selection mode"
        : "Activate selection mode";
    return (_jsx(ViewerButtons, Object.assign({ onClick: onClick, tooltipMsg: tooltipMsg ? tooltipMsg : _tooltipMsg, tooltipPlacement: placement, disabled: disabled, active: active, paletteCategory: "secondary", size: "small" }, { children: _jsx(NearMeIcon, { fontSize: "small" }) })));
};
export const MeasureButton = ({ onClick, active, }) => {
    //SquareFootIcon
    return (_jsx(ViewerButtons, Object.assign({ active: active, onClick: onClick, tooltipMsg: "Measure", size: "small", paletteCategory: "secondary" }, { children: _jsx(SquareFootIcon, { fontSize: "small" }) })));
};
export const DeleteButton = ({ onClick, tooltipMsg, confirmationMsg, placement, active, disabled, }) => {
    const confirmDeletion = () => __awaiter(void 0, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: confirmationMsg || "",
        });
        if (ok)
            onClick();
    });
    return (_jsx(_Fragment, { children: _jsx(ViewerButtons, Object.assign({ onClick: confirmationMsg ? confirmDeletion : onClick, tooltipMsg: tooltipMsg, size: "small", paletteCategory: "error", tooltipPlacement: placement, disabled: disabled, active: active }, { children: _jsx(DeleteIcon, { className: "delIcon", fontSize: "small" }) })) }));
};
