var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { addRepresentation } from "../3dViewer/showFile";
import { findSystemByKey } from "../3dViewer/stateTree";
import { createMolstarModels, createMolstarModelsMultipleMols, } from "../3dViewer/createMolstarModels";
import { getMoleculeTopologyAndCoordinates } from "../FileLoaders/loadInMoleculeKit";
import { getFileType } from "../FileLoaders/utils/utils";
import { setTrajectoryMode } from "../FileLoaders/loadTrajShowingMultipleFrames";
import { getActiveSystemEntries } from "../utils/IndexSelector/indexSelectorToIndexArray";
export const updateMoleculeReps = (vss, molstar, pyodideWorker, moleculeID) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const cameraSnapshot = (_a = molstar.canvas3d) === null || _a === void 0 ? void 0 : _a.camera.getSnapshot();
    const loaded_structures = vss.getState().loaded_structures;
    const system = findSystemByKey(loaded_structures, "moleculeID", moleculeID);
    if (!system)
        throw Error(`System with ID ${moleculeID} not found.`);
    const oldCellRefs = system === null || system === void 0 ? void 0 : system.cellRef;
    if (!oldCellRefs)
        throw Error(`System ${system.name} has no cellRefs.`);
    // Delete old reps
    yield deleteMolstarCells(molstar, oldCellRefs);
    // Apply modifications to the system if needed
    const systParamsAfterUpdate = vss.getState().systParamsAfterUpdate;
    if (moleculeID in systParamsAfterUpdate) {
        for (const param in systParamsAfterUpdate[moleculeID]) {
            system[param] = systParamsAfterUpdate[moleculeID][param];
        }
        vss.getState().removeSystemParamsAfterUpdate(moleculeID);
    }
    // Create reps of the new molecule (the same reps as before)
    const newSystParams = yield createNewReps(system, molstar, pyodideWorker, moleculeID);
    if (cameraSnapshot)
        (_b = molstar.canvas3d) === null || _b === void 0 ? void 0 : _b.camera.setState(cameraSnapshot);
    const positionInTree = system.stateTreePosition;
    if (!positionInTree)
        return;
    vss.getState().updateSystemParameters(positionInTree, newSystParams);
});
function createNewReps(system, molstar, pyodideWorker, moleculeID) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const systUpdateParams = {};
        const isSDF = yield pyodideWorker.RunPythonAsync({
            context: {},
            script: `is_sdf("${moleculeID}")`,
        });
        // const isSDF = system.type === "sdf";
        const smoothTraj = (_a = system.trajectoryParameters) === null || _a === void 0 ? void 0 : _a.smooth;
        const molsPre = yield getMoleculeTopologyAndCoordinates(isSDF, moleculeID, pyodideWorker, isSDF ? undefined : smoothTraj);
        const mols = molsPre.filter((mol) => mol.numAtoms > 0);
        const ftype = getFileType(mols[0], isSDF);
        systUpdateParams["type"] = ftype;
        let cellRefs = [];
        let visibleElementIDs;
        let numEntries;
        if (isSDF && mols.length > 1) {
            visibleElementIDs = system.visibleElementIDs
                ? system.visibleElementIDs
                : "all";
            const cellRef = yield createMolstarModelsMultipleMols(molstar, mols, system.name, ftype, visibleElementIDs);
            cellRefs = cellRef;
            numEntries = mols.length;
        }
        else {
            const mol = mols[0];
            const cellRef = yield createMolstarModels(molstar, mol, system.name, ftype);
            const { cellRef: _cellRef, trajMode } = yield setTrajectoryMode(ftype, cellRef, molstar, mol, (_b = system.trajectoryParameters) === null || _b === void 0 ? void 0 : _b.mode);
            if (system.trajectoryParameters) {
                systUpdateParams["trajectoryParameters"] = Object.assign(Object.assign({}, system.trajectoryParameters), { mode: trajMode });
            }
            cellRefs = _cellRef;
        }
        systUpdateParams["cellRef"] = cellRefs;
        systUpdateParams["visibleElementIDs"] = visibleElementIDs;
        systUpdateParams["numEntries"] = numEntries;
        systUpdateParams["aromaticBonds"] = mols[0].bondtype.includes("ar");
        const activeSystemEntries = getActiveSystemEntries(system);
        const addedReps = yield addRepresentation(molstar, pyodideWorker, isSDF, moleculeID, isSDF, isSDF ? undefined : mols[0], systUpdateParams["cellRef"], system.visibility, system.representations, systUpdateParams["aromaticBonds"], activeSystemEntries);
        systUpdateParams["representations"] = addedReps;
        // if (isSDF) {
        //   const isVisible = system.visibility !== false;
        //   if (
        //     isVisible &&
        //     system.visibleElementIDs &&
        //     system.visibleElementIDs !== "all"
        //   ) {
        //     // Set visibility according to the visible elements of the small mol lib
        //     for (const rep of systUpdateParams["representations"]!) {
        //       if (!rep.visibility || !rep.refs) continue;
        //       setVisibilityForSDF(molstar, system.visibleElementIDs, rep.refs);
        //     }
        //   }
        // }
        return systUpdateParams;
    });
}
function deleteMolstarCells(molstar, cellRefs) {
    var _a, cellRefs_1, cellRefs_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            for (_a = true, cellRefs_1 = __asyncValues(cellRefs); cellRefs_1_1 = yield cellRefs_1.next(), _b = cellRefs_1_1.done, !_b;) {
                _d = cellRefs_1_1.value;
                _a = false;
                try {
                    const ref = _d;
                    yield PluginCommands.State.RemoveObject(molstar, {
                        state: molstar.state.data,
                        ref: ref,
                    });
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = cellRefs_1.return)) yield _c.call(cellRefs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
}
