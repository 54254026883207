// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlobReader, BlobWriter, TextWriter, Uint8ArrayWriter, ZipReader, } from "@zip.js/zip.js";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../../NotificationSystem/utils";
import { Buffer } from "buffer";
import { binFormats, jsonFormats } from "../../utils/FileUpload/supportedFiles";
export function decompressStateExport(zipFile, fileName) {
    return __awaiter(this, void 0, void 0, function* () {
        const zipName = fileName
            ? fileName
            : "name" in zipFile
                ? zipFile.name
                : "zip";
        if (zipFile.type !== "application/zip") {
            dispatchNotificationEvent({
                type: "error",
                message: `Wrong file format in ${zipName}`,
            });
            return;
        }
        dispatchControlledNotification({
            idx: zipName,
            type: "info",
            message: `Reading...`,
            fileName: zipName,
        });
        try {
            /* Instantiate zip reader */
            const zipFileReader = new BlobReader(zipFile);
            /* Creates a ZipReader object reading the zip content via `zipFileReader`*/
            const zipReader = new ZipReader(zipFileReader);
            const zipEntries = yield zipReader.getEntries();
            const decompressedEntries = [];
            for (const entry of zipEntries) {
                if (entry.directory)
                    continue;
                if (!(entry === null || entry === void 0 ? void 0 : entry.getData))
                    throw new Error("Zip entry data not found");
                const ext = entry === null || entry === void 0 ? void 0 : entry.filename.split(".").pop();
                let data;
                if (ext && binFormats.includes(ext)) {
                    const uint8ArrayData = yield entry.getData(new Uint8ArrayWriter());
                    data = Buffer.from(uint8ArrayData);
                    if (ext === "png") {
                        data = data.toString("base64");
                    }
                }
                else {
                    if (ext === "csv") {
                        data = yield entry.getData(new BlobWriter(), {
                        // onprogress: async (index, max) => {
                        //   console.log(`Progress: ${(index / max) * 100}%`);
                        // },
                        });
                        // data = await parseCSVString(dataBlob, "_pmv_originalId", false);
                    }
                    else {
                        const dataPre = yield entry.getData(new TextWriter());
                        // const dataBlob = await entry.getData(new BlobWriter());
                        // const dataPre = await dataBlob.text();
                        if (ext && jsonFormats.includes(ext)) {
                            data = JSON.parse(dataPre);
                        }
                        else {
                            data = dataPre;
                        }
                    }
                }
                decompressedEntries.push({
                    name: entry.filename,
                    size: entry.compressedSize,
                    type: "text/plain",
                    lastModified: entry.lastModDate,
                    data: data,
                    isLoaded: true,
                });
            }
            yield zipReader.close();
            dispatchDeleteControlledNotification({
                idx: zipName,
            });
            return decompressedEntries;
        }
        catch (e) {
            dispatchNotificationEvent({
                type: "error",
                message: `Error reading ${zipName}. ${e}`,
            });
            dispatchDeleteControlledNotification({
                idx: zipName,
            });
        }
    });
}
/**
 * Loads all file types specified in the given loading instructions and adds
 * successfully rendered systems to the VSS state.
 */
