// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { NAPGenericStore } from "../../GenericViewerState";
export function onStructureLoadCallback(systemsLoaded, setLocation) {
    if (systemsLoaded) {
        const activePanels = NAPGenericStore.getState()._activePanels;
        NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { statetree: true, apps: false }));
        setLocation("/");
    }
}
