// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { convertLoadedFilesPathToRelative, getFilesFullPath, getFilesInConfig, getFilesNotInConfig, } from "./utils/utils";
import { loadLocalFiles } from "./loadLocalFiles";
import { readConfigFile } from "./FileReaders/readFileWrappers";
import { cleanMissingFiles } from "./utils/cleanMissingFiles";
export function loadingInstructionsFromFiles(configFilesFound, acceptedFilesObject, isZip) {
    var _a, configFilesFound_1, configFilesFound_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const instructionsSet = [];
        const filesInInstructions = [];
        try {
            for (_a = true, configFilesFound_1 = __asyncValues(configFilesFound); configFilesFound_1_1 = yield configFilesFound_1.next(), _b = configFilesFound_1_1.done, !_b;) {
                _d = configFilesFound_1_1.value;
                _a = false;
                try {
                    const configFile = _d;
                    let napFilePath;
                    let isDesktop = false;
                    if ("path" in configFile) {
                        napFilePath = configFile["path"];
                        isDesktop = true;
                    }
                    else if (isZip) {
                        napFilePath = configFile.name;
                    }
                    const localBasePath = napFilePath
                        ? napFilePath.substring(0, napFilePath.lastIndexOf("/"))
                        : undefined;
                    const instructions = yield loadingInstructionsFromFile(configFile, acceptedFilesObject, localBasePath, isDesktop);
                    if (instructions) {
                        const _filesInInstructions = getFilesInConfig(instructions);
                        filesInInstructions.push(..._filesInInstructions);
                        instructionsSet.push({
                            instructions,
                            localBasePath: isDesktop ? localBasePath : undefined,
                        });
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = configFilesFound_1.return)) yield _c.call(configFilesFound_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        const filesNotInConfig = getFilesNotInConfig(filesInInstructions, acceptedFilesObject);
        if (filesNotInConfig.length > 0) {
            dispatchNotificationEvent({
                message: `The following files are not refferenced in the session file${configFilesFound.length > 1 ? "s" : ""} and will be skipped: ${filesNotInConfig.join(", ")}`,
                type: "warning",
            });
        }
        return instructionsSet;
    });
}
export function loadingInstructionsFromFile(confFile, acceptedFilesObject, localBasePath, isDesktop) {
    return __awaiter(this, void 0, void 0, function* () {
        let loadingInstructions;
        if (confFile["isLoaded"]) {
            loadingInstructions = confFile.data;
        }
        else {
            try {
                loadingInstructions = yield readConfigFile(confFile);
            }
            catch (error) {
                dispatchNotificationEvent({ message: error, type: "error" });
                return;
            }
        }
        if (localBasePath) {
            if (isDesktop) {
                getFilesFullPath(loadingInstructions, localBasePath);
                yield loadLocalFiles(loadingInstructions, acceptedFilesObject);
            }
            else {
                // for zip files in web, update acceptedFilesObject so that it corresponds to the paths in the session file
                convertLoadedFilesPathToRelative(acceptedFilesObject, localBasePath);
            }
        }
        const missingFiles = cleanMissingFiles(loadingInstructions, acceptedFilesObject);
        if (missingFiles.length > 0) {
            const fileName = confFile.name.split("/").pop() || confFile.name;
            const errorMsg = `The following files refferenced in the session file ${fileName} are missing:`;
            dispatchNotificationEvent({
                message: `${errorMsg} ${missingFiles.join(", ")}`,
                type: "error",
            });
        }
        return loadingInstructions;
    });
}
