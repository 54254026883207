// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { cleanTreeForDownload, downloadZip, getDateNow, getMSAMappingConfig, getSystemsDataToZip, getZipFileNameBase, updateTreeFiles, } from "./utils";
import { dispatchNotificationEvent } from "../../NotificationSystem";
import { NAPMSAStore, NAPPlotStore, NAPTableStore } from "../../StateStores";
import { createCSVBlob } from "../../StateStores/tables.store";
import uuidv4 from "../../utils/UUID";
import { PlotType } from "../../StateStores/plots.store";
import { createCSVBlobfromMSA } from "../../StateStores/MSA.store";
import { ZipWriter, BlobWriter, BlobReader, TextReader } from "@zip.js/zip.js";
export const exportZip = (pyodide, vss) => __awaiter(void 0, void 0, void 0, function* () {
    // try {
    // Create zip file
    const zipWriter = new ZipWriter(new BlobWriter("application/zip"));
    // -------  Add files to zip file
    // await pyodide.FS.writeFile("vfs_test.txt", "hello :)!!", "utf8");
    // await addVFSFileToZip(zipWriter, "vfs_test.txt", "test.txt", pyodide);
    const loadingInstructions = {};
    // Systems
    const systemsTree = vss.getState().loaded_structures;
    const exportSystemsData = yield exportSystems(zipWriter, pyodide, systemsTree);
    const systConfig = exportSystemsData === null || exportSystemsData === void 0 ? void 0 : exportSystemsData.systConfig;
    if (systConfig && systConfig.length > 0) {
        loadingInstructions["systems"] = systConfig;
    }
    // Tables
    const tablesConfig = yield exportTables(zipWriter);
    if (tablesConfig && tablesConfig.length > 0) {
        loadingInstructions["tables"] = tablesConfig;
    }
    // Plots
    const plotsConfig = yield exportPlots(zipWriter);
    if (plotsConfig && plotsConfig.length > 0) {
        loadingInstructions["plots"] = plotsConfig;
    }
    // MSA
    const msasConfig = yield exportMSAs(zipWriter, systemsTree);
    if (msasConfig && msasConfig.length > 0) {
        loadingInstructions["MSAs"] = msasConfig;
    }
    // Save loading instructions to zip file
    yield zipWriter.add("config.pmv", new TextReader(JSON.stringify(loadingInstructions)));
    // Close the zip and get the Blob
    const zipBlob = yield zipWriter.close();
    if (Object.keys(loadingInstructions).length > 0) {
        // Download zip file
        const zipName = `pmv_${getDateNow()}.zip`;
        downloadZip(zipBlob, zipName);
    }
    else {
        dispatchNotificationEvent({
            message: "No data to export - session file would be empty.",
            type: "error",
        });
    }
    // } catch (e) {
    //   dispatchNotificationEvent({
    //     message: `Error exporting PMV session: ${e}`,
    //     type: "error",
    //   });
    // }
});
const exportSystems = (zipWriter, pyodide, systemsTree) => __awaiter(void 0, void 0, void 0, function* () {
    if (systemsTree.length === 0)
        return;
    const savedSystemsFiles = yield getSystemsDataToZip(zipWriter, systemsTree, pyodide);
    // Systems config
    const { missingFiles, filtSystems } = updateTreeFiles(systemsTree, savedSystemsFiles);
    if (missingFiles.length > 0) {
        dispatchNotificationEvent({
            message: `The following systems could not be included in the PMV session file: ${missingFiles.join(", ")}`,
            type: "error",
        });
    }
    const cleanSystems = cleanTreeForDownload(filtSystems);
    return {
        systConfig: cleanSystems,
        savedSystemsFiles: savedSystemsFiles,
    };
});
const exportTables = (zipWriter) => __awaiter(void 0, void 0, void 0, function* () {
    const tablesData = NAPTableStore.getState().tables;
    if (tablesData.length === 0)
        return;
    const tablesConfig = [];
    for (const table of tablesData) {
        const tableBlob = createCSVBlob(table.columns, table.rows);
        const tableName = `${getZipFileNameBase(table.name, uuidv4())}.csv`;
        yield zipWriter.add(tableName, new BlobReader(tableBlob));
        // Tables config
        const tc = {
            path: tableName,
            name: table.name,
        };
        if (table.options)
            tc["options"] = table.options;
        tablesConfig.push(tc);
    }
    return tablesConfig;
});
const exportPlots = (zipWriter) => __awaiter(void 0, void 0, void 0, function* () {
    const plotsData = NAPPlotStore.getState().plots;
    if (plotsData.length === 0)
        return;
    const plotsConfig = [];
    for (const plot of plotsData) {
        let plotContent;
        let ext = "";
        if (plot.type === PlotType.interactive) {
            const plotStr = JSON.stringify(plot.contents);
            plotContent = new TextReader(plotStr);
            ext = "plot.json";
        }
        else {
            ext = plot.path.split(".").pop() || "png";
            const plotStr = plot.contents;
            if (ext === "png") {
                // For PNG files, need to convert base64 string to blob
                const byteString = atob(plotStr);
                const byteArray = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([byteArray], { type: "image/png" });
                plotContent = new BlobReader(blob);
            }
            else {
                plotContent = new TextReader(plotStr);
            }
        }
        const plotName = `${getZipFileNameBase(plot.name, uuidv4())}.${ext}`;
        yield zipWriter.add(plotName, plotContent);
        const pc = {
            path: plotName,
            name: plot.name,
        };
        if (plot.options)
            pc["options"] = plot.options;
        plotsConfig.push(pc);
    }
    return plotsConfig;
});
const exportMSAs = (zipWriter, systemsTree) => __awaiter(void 0, void 0, void 0, function* () {
    const msasData = NAPMSAStore.getState().msaArr;
    if (msasData.length === 0)
        return;
    const msasConfig = [];
    for (const msa of msasData) {
        // Write msa.data to csv
        const msaBlob = createCSVBlobfromMSA(msa.data);
        const msaName = `${getZipFileNameBase(msa.name, uuidv4())}.csv`;
        yield zipWriter.add(msaName, new BlobReader(msaBlob));
        // MSA config
        const mc = {
            path: msaName,
            name: msa.name,
            // coloring (?)
        };
        // Write msa.mapping to file
        if (msa.mapping) {
            const msaMappingConfig = getMSAMappingConfig(msa.mapping, systemsTree);
            if (msaMappingConfig) {
                const { mappingConf, mappingConfFileName } = msaMappingConfig;
                yield zipWriter.add(mappingConfFileName, new TextReader(mappingConf));
                mc["mapping"] = msaMappingConfig.mappingConfFileName;
            }
        }
        msasConfig.push(mc);
    }
    return msasConfig;
});
