// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { TableClickAction, } from "..";
import { createCSVBlob, NAPTableStore } from "../StateStores/tables.store";
import { cameraFocus } from "../3dViewer/Controls/CameraControls/cameraFocus";
import { get_system_by_cellref, get_representation_ref_by_name } from "..";
import { findSystemsByKey } from "../3dViewer/stateTree";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { bottomPanel, consoleMinHeight } from "../UIPanels/panelDimensions";
export function findSystemFileInTree(systems, fileName) {
    let fileCellRefs;
    systems.forEach((system) => {
        const foundCellRefs = findSystemFileInTreeNode(system, fileName);
        if (foundCellRefs)
            fileCellRefs = foundCellRefs;
    });
    return fileCellRefs;
}
function findSystemFileInTreeNode(system, fileName) {
    if (system.files) {
        if (system.files.includes(fileName)) {
            return system;
        }
    }
    else if (system.children) {
        let result;
        for (let i = 0; i < system.children.length && result == null; i++) {
            result = findSystemFileInTreeNode(system.children[i], fileName);
        }
        return result;
    }
}
function assignRowToSystem(systems, _row) {
    const systemsColumn = "Path";
    if (!(systemsColumn in _row))
        return;
    const filePath = _row[systemsColumn];
    const fileName = filePath.split("/").pop();
    if (!fileName)
        return;
    const fileSyst = findSystemFileInTree(systems, fileName);
    const fileCellRefs = fileSyst === null || fileSyst === void 0 ? void 0 : fileSyst.cellRef;
    if (fileCellRefs)
        _row["cellRefs"] = fileCellRefs;
}
function assignRowsToSystem(systems, rows) {
    rows.forEach((row) => {
        assignRowToSystem(systems, row);
    });
}
export const syncTableToSystems = (systems) => {
    const t = NAPTableStore.getState().tables;
    const updated_tables = produce(t, (draft) => {
        draft.forEach((table) => {
            assignRowsToSystem(systems, table.rows);
        });
    });
    NAPTableStore.getState().set_tables(updated_tables);
};
export function focusOnSystem(molstar, cellRefs, systems) {
    if (cellRefs.length === 0)
        return;
    let foundRef;
    for (const cellRef of cellRefs) {
        const system = get_system_by_cellref(systems, cellRef);
        if (system) {
            foundRef = get_representation_ref_by_name(system, "Ligand");
            if (foundRef)
                break;
            foundRef = get_representation_ref_by_name(system, "Protein");
            if (foundRef)
                break;
            foundRef = get_representation_ref_by_name(system, "All");
            if (foundRef)
                break;
            const firstRef = system.representations
                ? system.representations[0]
                : undefined;
            foundRef =
                firstRef.refs && firstRef.refs.length > 0
                    ? firstRef.refs[0].repRef
                    : undefined;
            if (foundRef)
                break;
        }
    }
    if (foundRef)
        cameraFocus(molstar, [foundRef]);
}
/*
  this could be generalized by providing the keys to be joined
  (hopefully without much effort)
*/
export function joinMeanAndStdColumns(tableArray) {
    const exRow = tableArray[0];
    const headers = Object.keys(exRow);
    const colsToJoin = [];
    headers.forEach((colName) => {
        if (colName.endsWith(" mean")) {
            const prefix = colName.slice(0, -5);
            const sdName = `${prefix} std`;
            if (headers.includes(sdName)) {
                colsToJoin.push(prefix);
            }
        }
    });
    if (colsToJoin.length === 0)
        return;
    tableArray.forEach((row) => {
        colsToJoin.forEach((prefix) => {
            const meanVal = row[`${prefix} mean`];
            const sdVal = row[`${prefix} std`];
            row[`${prefix} (std)`] = `${showInExponentialNotation(meanVal)} (${showInExponentialNotation(sdVal)})`;
            delete row[`${prefix} mean`];
            delete row[`${prefix} std`];
        });
    });
    // const headersPre = Object.keys(tablejson);
    // headersPre.forEach((colName) => {
    //   if (colName.endsWith(" mean")) {
    //     const prefix = colName.slice(0, -5);
    //     const sdName = `${prefix} std`;
    //     if (headersPre.includes(sdName)) {
    //       const rowIDs = Object.keys(tablejson[colName]);
    //       const mergedColDat: { [id: string]: string } = {};
    //       rowIDs.forEach((i: string) => {
    //         const meanVal = tablejson[colName][i];
    //         const sdVal = tablejson[sdName][i];
    //         mergedColDat[i] = `${meanVal} (${sdVal})`;
    //       });
    //       tablejson[`${prefix} (std)`] = mergedColDat;
    //       delete tablejson[colName];
    //       delete tablejson[sdName];
    //     }
    //   }
    // });
}
const prettifiedTableLabels = {
    pkd: "pK<sub>d</sub>",
    pki: "pKI",
    kd: "K<sub>d</sub>",
    ki: "KI",
    ic50: "IC50",
    pic50: "pIC50",
    dg: "ΔG",
};
function replacer(match, p1, p2, p3, offset, string) {
    return p1 + prettifiedTableLabels[p2.toLowerCase()] + p3;
}
// (match start or space)  (any of the keywords)  (match end or space)
//            Group1          Group2             Group3
//             |----||---------------------------||----| |```` case insensitive search
const regex = /(^|\s)(pkd|pki|kd|ki|ic50|pic50|dg)($|\s)/i;
export function prettifyLabel(label) {
    return label.replace(regex, replacer).replace(/_/g, " ");
}
function roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
}
export function showInExponentialNotation(arg, upperBound = 99999, lowerBound = 0.0001) {
    if (typeof arg !== "number")
        return arg;
    if (Number.isInteger(arg))
        return arg;
    if (Math.abs(arg) < lowerBound || Math.abs(arg) > upperBound) {
        // || decimalCount(arg) > 5
        return arg.toExponential(4);
    }
    else
        return roundNumber(arg, 4);
}
export function decimalCount(value) {
    if (Math.floor(value) === value)
        return 0;
    return value.toString().split(".")[1].length || 0;
}
export function getComponentYDistance(tableContainerRefCurrent) {
    if (tableContainerRefCurrent) {
        const rect = tableContainerRefCurrent.getBoundingClientRect();
        return rect.top + window.scrollY;
    }
    return 0;
}
export function calculateTableHeight(yPosition, navigationMode, consoleIsOpen) {
    const consoleHeight = consoleIsOpen
        ? ` - ${bottomPanel.height}`
        : ` - ${consoleMinHeight}`;
    return `calc(100vh  - ${yPosition}px${navigationMode ? " - 50px" : ""}${consoleHeight})`;
}
export function hideSystemsFromTable(vss, molstar) {
    const systems = vss.getState().loaded_structures;
    const cellRefsToUpdate = [];
    const newSystems = produce(systems, (draftSystems) => {
        const tableSystems = findSystemsByKey(draftSystems, "category", [
            "data-viz-interaction",
        ]);
        tableSystems.forEach((syst) => {
            if (syst.visibility !== false) {
                if (syst.cellRef)
                    cellRefsToUpdate.push(...syst.cellRef);
                syst.visibility = false;
            }
            syst.archived = true;
        });
    });
    setVisibility(molstar, cellRefsToUpdate, false);
    vss.getState().set_loaded_structures(newSystems);
}
export function unArchiveTableSystems(vss) {
    const systems = vss.getState().loaded_structures;
    const newSystems = produce(systems, (draftSystems) => {
        const tableSystems = findSystemsByKey(draftSystems, "category", [
            "data-viz-interaction",
        ]);
        tableSystems.forEach((syst) => {
            syst.archived = false;
        });
    });
    vss.getState().set_loaded_structures(newSystems);
}
export const downloadTable = (columns, data, name) => {
    // const tableStr = reconstructCSV(columns, data);
    // const content = tableStr;
    // const blobtype = "text/plain";
    // const blob = new Blob([content], {
    //   type: blobtype,
    // });
    const csvBlob = createCSVBlob(columns, data);
    const url = URL.createObjectURL(csvBlob);
    const anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.download = `${name}.csv`;
    anchorElement.click();
    window.URL.revokeObjectURL(url);
};
export function getHiddenAndIgnoredColumns(options, columnNames) {
    let _hiddenColumns = [];
    if (options && options.hasOwnProperty("hideColumns")) {
        _hiddenColumns = columnNames.filter((x) => { var _a; return (_a = options.hideColumns) === null || _a === void 0 ? void 0 : _a.some((c) => c.toLowerCase() === x.toLowerCase()); });
    }
    let _ignoredColumns = [];
    if (options && options.hasOwnProperty("ignoreColumns")) {
        _ignoredColumns = columnNames.filter((x) => { var _a; return (_a = options.ignoreColumns) === null || _a === void 0 ? void 0 : _a.some((c) => c.toLowerCase() === x.toLowerCase()); });
    }
    return { _hiddenColumns, _ignoredColumns };
}
export const addNewColToOrder = (table, refColId, newColName, addToRight) => {
    const columnOrder = [...table.getState().columnOrder];
    if (!columnOrder || columnOrder.length === 0)
        return;
    const refColIdx = columnOrder.findIndex((col) => col === refColId);
    const addIdx = addToRight ? refColIdx + 1 : refColIdx;
    columnOrder.splice(addIdx, 0, newColName);
    table.setColumnOrder(columnOrder);
};
export function setSystemCategoryDataViz(syst, vss) {
    if (!syst.category && syst.stateTreePosition) {
        vss.getState().updateSystemParameters(syst.stateTreePosition, {
            category: "data-viz-interaction",
        });
    }
}
export function getSystemEntryToggleColValues(row, rowOnClickActions) {
    const colData = [];
    const actionCols = rowOnClickActions.filter((act) => act.action === TableClickAction.showSystemEntry);
    actionCols.forEach((act) => {
        var _a, _b;
        const systCols = act.column.map((col) => row.original[col]);
        const elementCols = (_b = (_a = act.options) === null || _a === void 0 ? void 0 : _a.showIndexColumn) === null || _b === void 0 ? void 0 : _b.map((col) => row.original[col]);
        if (elementCols === undefined)
            return;
        systCols.forEach((systCol, i) => {
            if (systCol === undefined)
                return;
            if (elementCols.length > i) {
                const elCol = elementCols[i];
                if (elCol !== undefined) {
                    const elIdx = Number(elCol);
                    if (!Number.isNaN(elIdx))
                        colData.push({ system: systCol, entryIdx: elIdx });
                }
            }
        });
    });
    return colData;
}
