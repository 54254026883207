var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { binFormats } from "../../utils/FileUpload/supportedFiles";
export function sanitizeFilename(userInput, extension) {
    // Remove existing extension if any
    userInput = userInput.replace(/\.[^/.]+$/, "");
    // Remove invalid characters and periods
    let sanitized = userInput.replace(/[\\/*?:"<>|.,]/g, "");
    // Replace spaces with underscores
    sanitized = sanitized.replace(/\s+/g, "_");
    // Trim leading and trailing whitespace
    sanitized = sanitized.trim();
    // Limit the length of the file name to 255 characters (excluding extension)
    const maxLength = extension ? 255 - extension.length - 1 : 255; // Subtract 1 for the dot before the extension
    if (sanitized.length > maxLength) {
        sanitized = sanitized.substring(0, maxLength);
    }
    // Add the extension
    if (extension) {
        sanitized += `.${extension}`;
    }
    return sanitized;
}
export function downloadSystem(pyodide, format, selection, moleculeID, name, from, to, stride) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield pyodide.RunPythonAsync({
            context: {},
            script: `
          save_molecule("${moleculeID}", "${format}", "${selection}", ${from}, ${to}, ${stride})
          `,
        });
        if (!result.success) {
            throw new Error(`${result.error}`);
        }
        const tmp_filepath = result.filePath;
        const isBin = binFormats.includes(format) || format.endsWith(".gz");
        const encoding = isBin ? "binary" : "utf8";
        const fileContent = yield pyodide.FS.readFile(tmp_filepath, encoding);
        let content;
        let blobtype;
        if (typeof fileContent === "string") {
            content = fileContent;
            blobtype = "text/plain";
        }
        else {
            content = new Uint8Array(fileContent);
            blobtype = "application/zip";
        }
        const blob = new Blob([content], {
            type: blobtype,
        });
        const url = URL.createObjectURL(blob);
        const fileName = sanitizeFilename(name, format);
        const anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = fileName;
        anchorElement.click();
        try {
            const pathIdx = tmp_filepath.lastIndexOf("/");
            const path = tmp_filepath.slice(0, pathIdx);
            yield pyodide.RunPythonAsync({
                context: {},
                script: `
          shutil.rmtree("${path}")
          `,
            });
        }
        catch (e) {
            console.error(e);
        }
    });
}
