import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useMemo, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import { NAPTableStore } from "../..";
import { Grid } from "@mui/material";
import { NAPPlotStore, PlotType, } from "../../StateStores/plots.store";
import { Panels, } from "../../GenericViewerState/SharedState";
import { InteractivePlot } from "./InteractivePlot";
import { ImagePlot } from "./ImagePlot";
import { DatavizControlOptions } from "../../DataViz";
import { InteractivePlotButtons } from "./InteractivePlotButtons";
import { SelectPlot } from "./SelectPlot";
import { getComponentYDistance } from "../../Tables/utils";
import { calculatePlotHeight } from "./utils";
import RoundDeleteButton from "../../components/Buttons/DeleteButton/RoundDeleteButton";
function Plot({ onDelete, path, type, contents, vss, molstar, pyodide, options, localBasePath, NAPGenericStore, skipButtons, }) {
    const extension = path.split(".").pop();
    const skipDelete = skipButtons
        ? skipButtons.includes(DatavizControlOptions.delete)
        : false;
    // Calculate the comopnent size, necessary to provide appropiate scroll
    const [plots] = NAPPlotStore((state) => [state.plots], shallow);
    const [tables] = NAPTableStore((state) => [state.tables], shallow);
    const [activePanels] = NAPGenericStore((state) => [state._activePanels], shallow);
    const consoleIsOpen = activePanels.console;
    const [yPosition, setYPosition] = useState(0);
    const containerRef = useRef(null);
    useEffect(() => {
        const newYPosition = getComponentYDistance(containerRef.current);
        if (newYPosition !== yPosition)
            setYPosition(newYPosition);
    }, [tables.length, plots.length, containerRef]); //Change to
    useEffect(() => {
        const handleResize = () => {
            const newYPosition = getComponentYDistance(containerRef.current);
            setYPosition(newYPosition);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [containerRef]);
    const height = useMemo(() => {
        return calculatePlotHeight(yPosition, consoleIsOpen);
    }, [yPosition, consoleIsOpen]);
    return (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { display: skipDelete ? "none" : "block" }, alignContent: "center" }, { children: _jsx(RoundDeleteButton, { onClick: onDelete, tooltipMsg: "Close plot", confirmationMsg: `Are you sure you want to close this plot?`, icon: "close" }) })), type === PlotType.interactive ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", alignContent: "center" }, { children: _jsx(InteractivePlotButtons, {}) }))) : null, _jsx(Grid, Object.assign({ item: true, xs: 12, sx: { order: 2, mt: 1 }, ref: containerRef }, { children: type === PlotType.image ? (_jsx(ImagePlot, { extension: extension ? extension : "png", content: contents, height: height })) : (_jsx(InteractivePlot, { content: contents, vss: vss, molstar: molstar, pyodide: pyodide, plotActions: options, localBasePath: localBasePath, NAPGenericStore: NAPGenericStore, height: height })) }))] }));
}
export function Plots({ NAPGenericStore, vss, molstar, pyodide, skipButtons, }) {
    const { plots, del_plot, activePlotIdx, set_activePlotIdx } = NAPPlotStore();
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    useEffect(() => {
        const _cb = (e) => {
            setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
        };
        window.addEventListener("PM-Loaded-Table", _cb);
        return () => {
            window.removeEventListener("PM-Loaded-Table", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const closePanel = () => setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    const onDelete = () => {
        del_plot(activePlotIdx, closePanel);
    };
    const activePlot = plots[activePlotIdx];
    const numPlots = plots.filter((p) => !p.archived).length;
    return numPlots > 0 ? (_jsxs(_Fragment, { children: [numPlots > 1 && (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { mr: 1 } }, { children: _jsx(SelectPlot, { handleChange: set_activePlotIdx, activePlotIdx: activePlotIdx, plots: plots }) }))), _jsx(Plot, { onDelete: onDelete, contents: activePlot.contents, name: activePlot.name, type: activePlot.type, path: activePlot.path, vss: vss, molstar: molstar, pyodide: pyodide, options: activePlot.options, localBasePath: activePlot.localBasePath, NAPGenericStore: NAPGenericStore, skipButtons: skipButtons })] })) : null;
}
